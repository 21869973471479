import { createFeatureSelector, createSelector } from '@ngrx/store';
import { DocumentsState } from './state';

export const DocumentsFeature = 'DocumentsFeature';

export const documentFeatureStateSelector = createFeatureSelector<DocumentsState>(DocumentsFeature);

export const selectDocuments = createSelector(
  documentFeatureStateSelector,
  (state: DocumentsState) => state.documents,
);

export const selectDocumentsLoading = createSelector(
  documentFeatureStateSelector,
  (state: DocumentsState) => state.loadingDocuments,
);

export const selectDocumentCreating = createSelector(
  documentFeatureStateSelector,
  (state: DocumentsState) => state.creatingDocument,
);

export const selectCloseCreatingDocumentModal = createSelector(
  documentFeatureStateSelector,
  (state: DocumentsState) => state.closeCreatingDocumentModal,
);

export const selectDocumentLoading = createSelector(
  documentFeatureStateSelector,
  (state: DocumentsState) => state.loadingDocument,
);

export const selectDocument = createSelector(
  documentFeatureStateSelector,
  (state: DocumentsState) => state.document,
);

export const selectDocumentEditing = createSelector(
  documentFeatureStateSelector,
  (state: DocumentsState) => state.editingDocument,
);

export const selectCloseEditingDocumentModal = createSelector(
  documentFeatureStateSelector,
  (state: DocumentsState) => state.closeEditingDocumentModal,
);

export const selectUploadingFiles = createSelector(
  documentFeatureStateSelector,
  (state: DocumentsState) => state.uploadingFiles,
);

export const selectUploadedFiles = createSelector(
  documentFeatureStateSelector,
  (state: DocumentsState) => state.uploadedFiles,
);

export const selectUploadingFilesCount = createSelector(
  documentFeatureStateSelector,
  (state: DocumentsState) => state.uploadingFilesCount,
);

export const selectSendingReport = createSelector(
  documentFeatureStateSelector,
  (state: DocumentsState) => state.sendingReport,
);

export const selectCloseSendingReportModal = createSelector(
  documentFeatureStateSelector,
  (state: DocumentsState) => state.closeSendingReportModal,
);

export const fromDocuments = {
  selectDocuments,
  selectDocumentsLoading,
  selectCloseCreatingDocumentModal,
  selectDocumentCreating,
  selectDocument,
  selectDocumentLoading,
  selectDocumentEditing,
  selectCloseEditingDocumentModal,
  selectUploadingFiles,
  selectUploadingFilesCount,
  selectUploadedFiles,
  selectSendingReport,
  selectCloseSendingReportModal,
};
