<div *ngIf="files">
  <div class="row">
    <div class="col">
      <strong>{{ 'labels.filesUpload' | transloco }}</strong>
    </div>
  </div>

  <div class="row" *ngFor="let item of files; let i = index">
    <div class="col-11">
      <p class="file-name">{{ item.relativePath }}</p>
    </div>
    <div
      *ngIf="isFileUploading(item)"
      class="col-1 progress-loader non-blocking file-status"
      [hidden]="false"
    >
      <div class="spinner-border" role="file-status"></div>
    </div>
    <div
      *ngIf="isFileUploaded(item)"
      class="col-1 check"
    >
      <mat-icon>check_circle_outline</mat-icon>
    </div>
  </div>
</div>
