import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { NgxFileDropEntry } from 'ngx-file-drop';
import { filter } from 'rxjs';
import { AppState } from 'src/app/core/store/state';
import { getFileName } from 'src/app/core/utils/files.utils';
import { isPresent } from 'src/app/core/utils/isPresent';
import { fromDocuments } from 'src/app/modules/documents/store/selectors';

@UntilDestroy()
@Component({
  selector: 'app-files-list',
  styleUrl: './files-list.scss',
  templateUrl: './files-list.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilesListTemplateComponent {
  @Input() files: NgxFileDropEntry[] | undefined;
  @Input() isOneFileSelected: boolean | undefined | null;
  @Input() isLotsFileSelected: boolean | undefined | null;

  constructor(
    private store$: Store<AppState>,
    public cdr: ChangeDetectorRef,
  ) {}

  public uploadingFiles: string[] = [];
  public uploadedFiles: string[] = [];

  public selectUploadingFiles$ = this.store$
    .select(fromDocuments.selectUploadingFiles)
    .pipe(untilDestroyed(this), filter(isPresent))
    .subscribe((names) => {
      this.uploadingFiles = names;
      this.cdr.markForCheck();
    });

  public selectUploadedFiles$ = this.store$
    .select(fromDocuments.selectUploadedFiles)
    .pipe(untilDestroyed(this), filter(isPresent))
    .subscribe((names) => {
      this.uploadedFiles = names;
      this.cdr.markForCheck();
    });

  isFileUploading(file: NgxFileDropEntry): boolean {
    console.log(file?.fileEntry.name);
    return this.uploadingFiles?.includes(getFileName(file?.fileEntry.name)) || false;
  }

  isFileUploaded(file: NgxFileDropEntry): boolean {
    console.log(file?.fileEntry.name);
    return this.uploadedFiles?.includes(getFileName(file?.fileEntry.name)) || false;
  }
}
