import { ChangeDetectionStrategy, Component, Input, Optional, Self } from '@angular/core';
import { ControlValueAccessor, FormControl, NgControl } from '@angular/forms';

@Component({
  selector: 'app-checkbox',
  templateUrl: './app-checkbox.component.html',
  styleUrl: './app-checkbox.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppCheckboxComponent implements ControlValueAccessor {
  @Input() label: string | undefined;
  @Input() id: string = '';
  @Input() fontSize: 'small' | 'normal' = 'small';

  get control(): FormControl {
    return this.ngControl?.control as FormControl;
  }

  constructor(@Self() @Optional() public ngControl: NgControl) {
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }

  onChange: any = (value: boolean) => {};
  onTouched: any = () => {};

  public registerOnChange(fn: (value: boolean) => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  public writeValue(value: any): void {}
}
